var $class="se2--banner-full-image-carousel",$name="BannerFullImageCarousel",$path="app/components/BannerFullImageCarousel/config.js",$this={$class,$name,$path,};export const bannerFullImageCarouselClassName = $class;
export const controlsClassName = 'controls';
export const hideControlsClassName = 'hide-bullets';
export const fixedBackgroundSelector = '.fixed-background';
export const logoImageSelector = '.logo-container img';
export const nextButtonClassName = 'nextButton';
export const previousButtonClassName = 'prevButton';
export const paginationBulletClassName = 'pagination-bullet';
export const scrollDownButtonClassName = 'scrolldown';
export const slideClassName = 'slide';
export const slideContentClassName = 'slide-content';
export const slideWithVideoSelector = '.slide-with-video';
export const videoSelector = `.${slideClassName} video`;

export const bannerNames = {
  bannerFullImageCarousel: 'BannerHeroImageCarousel',
  bannerHeroImageCarousel: 'BannerFullImageCarousel',
  bannerGamingExperienceCarousel: 'BannerGamingExperienceCarousel',
  bannerAndInsertedSmallImage: 'BannerAndInsertedSmallImage',
};
